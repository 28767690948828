import api from "@/common/services/api";

export default new class {
    baseUrl = "/partner-admins";

    getSelectData(data) {
        return api.instance.get("/get-select-data", {
            params: data
        });
    }

    getPartnerAdmins(data) {
        return api.instance.get(this.baseUrl, {
            params: data
        });
    }

    sendInvitation(data) {
        return api.instance.post("/partner-admin-invitation", data);
    }

    checkRegistrationLink(data) {
        return api.instance.get("/check-partner-admin-register-link", {
            params: data
        });
    }

    register(data) {
        return api.instance.post(this.baseUrl, data);
    }

    getPartnerAdminData(id) {
        return api.instance.get(this.baseUrl + '/' + id);
    }

    updatePartnerAdmin(id, data) {
        return api.instance.patch(this.baseUrl + "/" + id, data);
    }


};