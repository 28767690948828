<app-header>
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Partner Admin Registration</h1>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <Form novalidate @submit="submitForm" :validation-schema="validations" v-slot="{ errors }" autocomplete="off">
                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.first_name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="first_name" v-model="partnerAdmin.first_name" class="form-control" id="first_name" placeholder="First Name"></Field>
                    <label for="first_name" class="color-primary-dark font-13 font-600">First Name</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.first_name}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.last_name}">
                    <i class="fa fa-user"></i>
                    <Field as="input" type="text" name="last_name" v-model="partnerAdmin.last_name" class="form-control" id="last_name" placeholder="Last Name"></Field>
                    <label for="last_name" class="color-primary-dark font-13 font-600">Last Name</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.last_name}}
                    </template>
                </form-control>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                    <i class="fa fa-at"></i>
                    <Field as="input" type="email" name="email" :disabled="true" v-model="partnerAdmin.email" class="form-control" id="email" placeholder="Email"></Field>
                    <label for="email" class="color-primary-dark font-13 font-600">Email</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.email}}
                    </template>
                </form-control>

                <div class="input-group raw">
                    <div class="col-4">
                        <div>
                            <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.country}">
                                <app-select name="country" disabled="true" placeholder="Country" :options="$store.state.selectData.phoneCodes" value-prop="id" v-model="partnerAdmin.country_id">
                                    <template v-slot:default="slotProps">
                                        {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                    </template>
                                    <template v-slot:display="slotProps">
                                        <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                        {{ slotProps.item.name }} <span class="">(+{{ slotProps.item.phone_code }})</span>
                                    </template>
                                </app-select>
                                <template v-slot:error>{{errors.country}}</template>
                            </form-control>
                        </div>
                    </div>
                    <div class="col ms-2">
                        <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.phone}">
                            <i class="fa fa-mobile"></i>
                            <Field as="input" :disabled="true" type="text" name="phone" v-model="partnerAdmin.phone" class="form-control validate-name" id="phone" placeholder="Phone No"></Field>
                            <label for="phone" class="color-primary-dark font-13 font-600">Phone No</label>
                            <em>(required)</em>
                            <template v-slot:error>
                                {{errors.phone}}
                            </template>
                        </form-control>
                    </div>
                </div>

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.otp}">
                    <i class="fa fa-key"></i>
                    <Field as="input" type="number" name="otp" v-model="partnerAdmin.otp" class="form-control" id="otp" placeholder="OTP"></Field>
                    <label for="otp" class="color-primary-dark font-13 font-600">OTP</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.otp}}
                    </template>
                </form-control>

                <div class="d-flex justify-content-center mt-4">
                    <button class="btn btn-ripple btn btn-ripple-l font-600 font-13 gradient-highlight rounded-s">
                        Register
                    </button>
                </div>
            </Form>
        </div>
    </div>
</div>