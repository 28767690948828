import adminData from "../../services";
import router from "@/router"

export default {
    data() {
        return {
            partnerAdmin: {},
            formValidations: {
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country: v => !!v || "Country is required",
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
            },
        }
    },
    mounted() {
        //
    },
    methods: {
        submitForm() {
            this.showLoader(true);
            this.$popper.wait("Sending...");
            adminData.sendInvitation(this.partnerAdmin).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                router.push({
                    'name': 'partner-admin-home'
                });
            });
        },
    }
}