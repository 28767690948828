import partnerData from "../../services";

export default {
    data() {
        return {
            partnerAdmin: { },
        }
    },
    mounted() {
        partnerData.getPartnerAdminData(this.$route.params.id).then(response =>{
            this.partnerAdmin = response.data.data;
        });
    }
}