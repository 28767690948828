<app-header menu="no">
    <h1 class="header-title text-capitalize" style="letter-spacing:0.5px;">Add Partner Admin</h1>
    <a href="#" @click="$router.go(-1)" class="header-icon header-icon-1">
        <i class="fa fa-arrow-left"></i>
    </a>
</app-header>

<div class="page-title-clear"></div>

<div class="page-content">
    <div class="card card-style">
        <div class="content">
            <div class="col-12 text-center">
                <p class="color-highlight mb-0"></p>
            </div>

            <Form novalidate @submit="submitForm" :validation-schema="formValidations" v-slot="{ errors }" autocomplete="off">

                <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.email}">
                    <i class="fa fa-at"></i>
                    <Field as="input" type="email" name="email" :disabled="otpSent" v-model="partnerAdmin.email" class="form-control" id="email" placeholder="Email"></Field>
                    <label for="email" class="color-primary-dark font-13 font-600">Email</label>
                    <em>(required)</em>
                    <template v-slot:error>
                        {{errors.email}}
                    </template>
                </form-control>

                <div class="input-group raw">
                    <div class="col-4">
                        <div>
                            <form-control class="validate-field input-style-active" :class="{'is-invalid' : errors.country}">
                                <app-select name="country" :disabled="otpSent" placeholder="Country" :options="$store.state.selectData.phoneCodes" value-prop="id" v-model="partnerAdmin.country_id">
                                    <template v-slot:default="slotProps">
                                        {{ slotProps.item.emoji }} &nbsp;(+{{ slotProps.item.phone_code }})
                                    </template>
                                    <template v-slot:display="slotProps">
                                        <span class="font-600 me-2">{{ slotProps.item.emoji }}</span>
                                        {{ slotProps.item.name }} <span class="">(+{{ slotProps.item.phone_code }})</span>
                                    </template>
                                </app-select>
                                <template v-slot:error>{{errors.country}}</template>
                            </form-control>
                        </div>
                    </div>
                    <div class="col ms-2">
                        <form-control class="validate-field has-icon" :class="{'is-invalid' : errors.phone}">
                            <i class="fa fa-mobile"></i>
                            <Field as="input" :disabled="otpSent" type="number" name="phone" v-model="partnerAdmin.phone" class="form-control validate-name" id="phone" placeholder="Phone No" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="15"></Field>
                            <label for="phone" class="color-primary-dark font-13 font-600">Phone No</label>
                            <em>(required)</em>
                            <template v-slot:error>
                                {{errors.phone}}
                            </template>
                        </form-control>
                    </div>
                </div>

                <div class="d-flex justify-content-center mt-4">
                    <button class="btn btn-ripple btn btn-ripple-l font-600 font-13 primary-light-bg letterSpacing rounded-s">
                        Send Invitation
                    </button>
                </div>
            </Form>
        </div>
    </div>
</div>