import adminDataService from "../../services";
import router from "@/router"

export default {
    data() {
        return {
            adminData: {},
            selectData: {},
            formValidations: {
                first_name: v => !!v || "First Name is required",
                last_name: v => !!v || "Last Name is required",
                email: v => v ? (/.+@.+/.test(v) || "E-mail must be valid") : "E-mail is required",
                country_id: v => !!v || "Country is required",
                phone: v => v ? (/^\d+$/.test(v) || 'Phone must be valid') : "Phone is required",
                partner: v => !!v || "Partner selection is required",
            },
        }
    },
    mounted() {
        var select = {
            'type': ['partnerAll'],
        };
        

        adminDataService.getPartnerAdminData(this.$route.params.id).then(response => {
            var data = response.data.data;
            this.adminData.id = data.id;
            this.adminData.first_name = data.first_name;
            this.adminData.last_name = data.last_name;
            this.adminData.email = data.email;
            this.adminData.country_id = data.country.id;
            this.adminData.phone = data.phone;
            this.adminData.partners = data.partners.map(function (object) {
                return object['id'];
            });

            select['country_id'] = data.country.id;
            adminDataService.getSelectData(select).then(response => {
                this.selectData = response.data;
                window.init_template();
            });
            window.init_template();
        });
    },
    methods: {
        submitForm() {
            console.log("testing")
            this.showLoader(true);
            this.$popper.wait("Updating...");
            adminDataService.updatePartnerAdmin(this.adminData.id, this.adminData).then(response => {
                this.$popper.success(response.data.message);
                this.hideLoader();
                router.push({
                    'name': 'partner-admin-detail',
                    'params': {
                        'id': this.adminData.id
                    }
                });
            });
        },
    }
}